import React, { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { NavHashLink } from "react-router-hash-link";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { CategoryContext } from "../context/categorycontext";
import {BASEURL} from "../baseurl/url"

function FaqTwo({ id }) {
  const [dataProduct, setDataProduct] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataCategory, setDataCategory] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const pageSize = 3;

  console.log("dataCategory", dataCategory);

  const { langproduct } = useContext(CategoryContext);
  const params = useLocation();
  const searchParams = new URLSearchParams(params.search).get("category");

  const totalPages = Math.ceil(totalCount / pageSize);

  const { data, loading, error } = useContext(CategoryContext);
  console.log("categorydate", data);

  const getProduct = async (id, currentPage) => {
    try {
      const response = await axios.get(
        `${BASEURL}e-commerce/8/products/?category=${id}&page=${currentPage}&page_size=${pageSize}`,
        {
          headers: {
            "Accept-Language": langproduct || "ar",
          },
        }
      );
      console.log("API Response:", response.data); // Log the full response
      setDataProduct(response.data.results);
      setTotalCount(response.data.count);
      console.log("data", response.data.results);
    } catch (error) {
      console.log("error", error);
    }
  };
  async function getCategory() {
    const { data } = await axios.get(
      `${BASEURL}e-commerce/8/categories/${id}/`,

      {
        headers: {
          "Accept-Language": langproduct || "ar",
        },
      }
    );
    setDataCategory(data);
    console.log("dataass", data);
  }

  const handleClick = (index, id) => {
    setActiveIndex(index);
    handleSubCategory(id);
  };
  async function handleSubCategory(id) {
    try {
      const response = await axios.get(
        `${BASEURL}e-commerce/8/products/?sub_category=${id}&page=${currentPage}&page_size=${pageSize}`,
        {
          headers: {
            "Accept-Language": langproduct || "ar",
          },
        }
      );
      console.log("API Response:", response.data); // Log the full response
      setDataProduct(response.data.results);
      setTotalCount(response.data.count);
      console.log("data", response.data.results);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    console.log("Fetching data for ID:", id, "Page:", currentPage);
    getProduct(id, currentPage);
    getCategory();
  }, [id, currentPage, langproduct]);

  const handlePageChange = (newPage) => {
    console.log("Changing to page:", newPage);
    setCurrentPage(newPage);
    window.scrollTo(800, 800);
    getProduct(id, currentPage);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      window.scrollTo(800, 800);
      getProduct(id, currentPage);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      window.scrollTo(800, 800);
      getProduct(id, currentPage);
    }
  };

  return (
    <section className="faq-two">
      <h2 className="text-center my-5">{dataCategory.name}</h2>
      <li className="nav-item px-3">
        <div
          className="d-flex justify-content-center text-center align-items-center my-5"
          id="potato"
        >
          {dataCategory?.sub_categories?.map((sub, index) => {
            const isActive = index === activeIndex;
            return (
              <div
                className={`text-success fs-4 ms-3 fw-bold cursor-pointer ${
                  isActive ? "coloractive" : ""
                }`}
                aria-current="page"
                key={index}
                onClick={() => handleClick(index, sub.id)}
              >
                {sub.name}
              </div>
            );
          })}
        </div>
      </li>

      <div className="container">
        <ul>
          {dataProduct.map((item, index) => {
            const { category_name, description, image } = item;
            return (
              <div key={index} className="row mt-4">
                <div className="col-xl-5">
                  <div className="faq-two__accordion">
                    <div className="sec-title-three">
                      <div className="sec-title-three__tagline">
                        <h6>{category_name}</h6>
                      </div>
                      <h2 className="sec-title-three__title fs-5">
                        {description}
                      </h2>
                      <NavHashLink
                        smooth
                        to="/contact"
                        activeClassName="selected"
                        activeStyle={{ color: "red" }}
                      >
                        <button
                          className="thm-btn"
                          type="submit"
                          data-loading-text="Please wait..."
                        >
                          <span className="txt">{t("BookNow")}</span>
                          <i className="icon-double-chevron"></i>
                        </button>
                      </NavHashLink>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7">
                  <div className="faq-two__content">
                    <div className="faq-two__content-img">
                      <img src={image} alt={category_name} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </ul>
        <div className="mt-5 pt-5">
          <div className="text-center mb-3">
            {t("Page")} {currentPage} {t("of")} {totalPages}
          </div>
          <nav>
            <ul className="pagination justify-content-center">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <a
                  onClick={handlePrevPage}
                  className="page-link"
                  href="#potato"
                >
                  {t("Previous")}
                </a>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  <a className="page-link" href="#potato">
                    {index + 1}
                  </a>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <a
                  onClick={handleNextPage}
                  className="page-link"
                  href="#potato"
                >
                  {t("Next")}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
}

export default FaqTwo;
