import { useFormik } from "formik";
import { t } from "i18next";
import React, { useState, useEffect, useContext } from "react";
import IconSocialContact from "./iconsocialcontact";
import { Circles } from "react-loader-spinner";
import axios from "axios";
import { Country } from "country-state-city";
import { toast } from "react-toastify";
import { logDOM } from "@testing-library/react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { CategoryContext } from "../context/categorycontext";
import { BASEURL } from "../baseurl/url";
function ContactForm() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  const schema = Yup.object().shape({
    phone: Yup.string().required(`${t("validationphone")}`),
    owner_name: Yup.string()
      .required(`${t("validationName")}`)
      .min(3, t("validationMinName", { min: 3 })),
    import_country: Yup.string().required(`${t("validationCountry")}`),
    description: Yup.string().required(`${t("validationDescription")}`),
    company_name: Yup.string().required(`${t("validationCompany_name")}`),
    product: Yup.string().required(`${t("validationProduct")}`),
    email:  Yup.string().email(`${t("validationEmail")}`).required(`${t("validationEmail")}`),
  });
  const { langproduct } = useContext(CategoryContext);

  async function getProduct() {
    const { data } = await axios.get(
      `${BASEURL}e-commerce/8/products`,
      {
        headers: {
          "Accept-Language": langproduct || "ar",
        },
      }
    );
    console.log("data", data.results);
    setData(data.results);
  }

  const formik = useFormik({
    initialValues: {
      owner_name: "",
      company_name: "",
      import_country: "",
      phone: "",
      description: "",
      product: "",
      email: "",
    },
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      // alert(JSON.stringify(values, null, 2));
      setLoading(true);

      try {
        const countryId = countries.find(
          (country) => country.name === values.import_country
        ).isoCode;

        const formData = {
          ...values,
          countryId,
        };

        try {
          await axios
            .post(
              `${BASEURL}e-commerce/8/reservation/`,
              formData
            )
            .then(() => {
              setLoading(false);
              resetForm();
              toast.success("تم ارسال بياناتك بنجاح", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        } catch (error) {
          setLoading(false);

          toast.error("لم يتم ارسال بياناتك", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          console.log("error", error.response.data);
          Object.keys(error.response.data)?.forEach((key) => {
            if (formik?.initialValues?.hasOwnProperty(key)) {
              formik.setFieldError(key, error.response.data[key][0]);
            }
          });
        }
      } catch (error) {
        console.error("Error finding country ID:", error);
        setLoading(false);
      }
    },
  });
  console.log("formik", formik.values);

  useEffect(() => {
    getProduct();
  }, []);
 
   console.log("formcsfik", formik.errors.email);
   
  return (
    <>
      <section className="contact-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="contact-one__form contact-one__form--contact">
                <div className="sec-title">
                  <h2 className="sec-title__title">{t("contact")}</h2>
                  <div className="my-5">
                    <IconSocialContact />
                  </div>
                </div>

                <form
                  onSubmit={formik.handleSubmit}
                  id="contact-form"
                  className="default-form2 contact-form-validated"
                >
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="input-box">
                        <input
                          type="text"
                          name="owner_name"
                          value={formik.values.owner_name}
                          placeholder={t("owner_name")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.errors.owner_name && formik.touched.owner_name ? (
                        <div className="text-danger text-center">
                          {formik.errors.owner_name}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="input-box">
                        <input
                          type="email"
                          name="email"
                          value={formik.values.email}
                          placeholder={t("email")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.errors.email && formik.touched.email ? (
                        <div className="text-danger text-center">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12  ">
                      <div className="input-box">
                        <input
                          type="text"
                          name="company_name"
                          value={formik.values.company_name}
                          placeholder={t("company_name")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.errors.company_name &&
                      formik.touched.company_name ? (
                        <div className="text-danger text-center">
                          {formik.errors.company_name}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="contact-two__input mb-3">
                      <div className="contact-two__input__label">
                        {/* <i className="far fa-phone" /> */}
                        <PhoneInput
                          type="text"
                          name="phone"
                          value={formik.values.phone}
                          placeholder={t("phone")}
                          onBlur={formik.handleBlur}
                          className="w-100 rounded-3 my-3"
                          onChange={(value) =>
                            formik.setFieldValue("phone", value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {formik?.errors?.phone && formik?.touched?.phone ? (
                    <div className="text-danger text-center ">
                      {formik?.errors?.phone}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row">
                    <div className="col-12 ">
                      <div className="select-box ">
                        <select
                          className="selectmenu  wide w-100 p-2 mb-3 px-3 border-0 text-muted pointer"
                          name="import_country"
                          value={formik.values.import_country}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option value="" disabled selected>
                            {t("SelectCountry")}
                          </option>
                          {countries.map((country) => (
                            <option key={country.isoCode} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formik.errors.import_country &&
                      formik.touched.import_country ? (
                        <div className="text-danger text-center">
                          {formik.errors.import_country}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="select-box">
                        <select
                          className="selectmenu wide w-100 p-2 mb-3 px-3 border-0 text-muted pointer"
                          name="product"
                          value={formik.values.product}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option value="" disabled>
                            {t("SelectProduct")}
                          </option>

                          {data.map((product, index) => (
                            <option key={index} value={product.id}>
                              {product.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formik.errors.product && formik.touched.product ? (
                        <div className="text-danger text-center">
                          {formik.errors.product}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                      <div className="input-box">
                        <textarea
                          name="description"
                          placeholder={t("description")}
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        ></textarea>
                        {formik.errors.description &&
                        formik.touched.description ? (
                          <div className="text-danger text-center">
                            {formik.errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="contact-one__form-btn">
                        <button
                          className="thm-btn d-flex align-items-center justify-content-center"
                          type="submit"
                        >
                          <span className="d-flex align-items-center justify-content-center my-1">
                            {loading ? (
                              <Circles
                                height="40"
                                width="50"
                                color="#fff"
                                ariaLabel="circles-loading"
                                visible={true}
                              />
                            ) : (
                              <>
                                {t("GetAQuote")}
                                <i className="fa fa-angle-double-right"></i>
                              </>
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactForm;
